import * as React from "react"
import { Button, Container } from 'react-bootstrap';
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import { useWindowSize } from '../../../hooks/winodw-size'
import GenerateLink from "../../common/site/generate-link"
import PartnerLogo1 from "../../../images/partner/arun-estates.svg"
import PartnerLogo2 from "../../../images/partner/logo-svg.svg"
import PartnerLogo3 from "../../../images/partner/ArunBrandBook.svg"
import PartnerLogo4 from "../../../images/partner/DA-1-Web-White.svg"
import PartnerLogo5 from "../../../images/partner/pittis.svg"
import PartnerLogo6 from "../../../images/partner/MMD-1-white.svg"

import "./Banner.scss"
// markup


const HomeBanner = (props) => {
  const [windowWidth] = useWindowSize();
  return (

    <React.Fragment>
      <div className="main-banner components--Home--Banner-Banner">
        <div className="main-banner-embed">
          {/* <video width="100%" height="100%" autoPlay playsInline loop muted className="video-banner">
            <source src={BannerVideo} type="video/mp4" />
          </video> */}


          {props.Banner_Image &&
            <GatsbyImage image={getImage(props.Banner_Image.url_sharp)} alt={props.Banner_Image.alternativeText} />
          }
        </div>
        <div className="main-banner-content">
          <Container>
            <div className="banner-text">
            <div className="parter-logos"> 
                <ul className="partner-logo d-md-flex">
                  <li className="partner-logo1"><a href="https://www.arunestates.co.uk/" target="_blank"><img src={PartnerLogo1} alt="Brand Logo" /></a></li>
                  <li className="partner-logo3"><a href="https://www.cubittandwest.co.uk/" target="_blank"><img src={PartnerLogo3} alt="" /></a></li>
                  <li className="partner-logo4"><a href="https://www.douglasallen.co.uk/" target="_blank"><img src={PartnerLogo4} alt="" /></a></li>
                  <li className="partner-logo5"><a href="https://www.pittis.co.uk/" target="_blank"><img src={PartnerLogo5} alt="" /></a></li>
                  <li className="partner-logo2"><a href="https://www.wardsofkent.co.uk/" target="_blank"><img src={PartnerLogo2} alt="" /></a></li>
                  <li className="partner-logo6"><a href="https://mortgagemattersdirect.co.uk/" target="_blank"><img src={PartnerLogo6} alt="" /></a></li>
                </ul> 
              </div>
              {parse(props.Banner_Content)}

              <div className="btn-wrap">
                {props.Banner_CTA_1_Link && props.Banner_CTA_1_Label &&
                  <GenerateLink link={props.Banner_CTA_1_Link} class="btn btn-secondary">
                    {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                    {props.Banner_CTA_1_Label}
                  </GenerateLink>
                }
                {props.Banner_CTA_2_Link && props.Banner_CTA_2_Label &&
                  <GenerateLink link={props.Banner_CTA_2_Link} class="btn">
                    {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                    {props.Banner_CTA_2_Label}
                  </GenerateLink>
                }
              </div>
             
            </div>

          </Container>
        </div>


      </div>
    </React.Fragment>
  )
}

export default HomeBanner